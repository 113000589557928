<template>
    <div>
      <v-container>
        <v-layout row wrap>
          <!--  -->
          <v-flex class="mt-10">
            <h1 class="text-center">Transactions</h1>
          </v-flex>
        </v-layout>
      </v-container>
      <v-container
        class="mb-10 mt-10 pa-5 white elevation-3"
        style="border-radius: 10px"
        v-if="TRANSACTIONS.length > 0"
      >
        <v-simple-table class="no-lines-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">#</th>
                <th class="text-left">Exchange</th>
                <th class="text-left">Coin</th>
                <th class="text-left">Timestamp</th>
                <th class="text-left">Quantity</th>
                <th class="text-left">Amount (&#8377;)</th>
                <th class="text-left">Type</th>
            
              </tr>
            </thead>
            <tbody class="no-lines-body">
              <tr
                v-for="(item, index) in TRANSACTIONS"
                :key="item.name"
                style="font-family: 'Poppins', sans-serif"
              >
                <td class="py-6">{{ index + 1 }}</td>
                <td>
                  <div class="d-flex align-center">
                    <img
                      :src="getExchangeLogoUrl(item.exchange)"
                      class="rounded-circle"
                      width="40"
                      height="40"
                    />
                    <div class="ms-3">
                      <p class="ma-0">{{ item.exchange }}</p>
                    </div>
                  </div>
                </td>
  
                <td>
                  <i class="cc mx-1" :class="item.coin"></i>{{ item.coin }}
                </td>
                <td>
                 {{ item.date }}
                </td>
                <td>
                  <p v-if="item.type == 'Buy' || item.type === 'Deposit'" class="green--text ma-0">
                    <v-icon class="green--text">mdi-menu-up</v-icon>
                    {{ item.quantity }}
                  </p>
                  <p v-else class="red--text ma-0">
                    <v-icon class="red--text">mdi-menu-down</v-icon>
                    {{ item.quantity }}
                  </p>
                </td>
                <td>
                  &#8377; {{ item.total }}
                </td>
                <td>{{ item.type }}</td>
              
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <h1 class="text-center mt-10 mb-10">Analysis</h1>
        <v-simple-table class="no-lines-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">#</th>
                <th class="text-left">Coin</th>
                <th class="text-left">Quantity</th>
                <th class="text-left">Sold On</th>
                <th class="text-left">Seling Price</th>
                <th class="text-left">Bought On</th>
                <th class="text-left">Bought At</th>
                <th class="text-left">P/L</th>
              </tr>
            </thead>
            <tbody class="no-lines-body">
              <tr
                v-for="(item, index) in computedPNL"
                :key="item.name"
                style="font-family: 'Poppins', sans-serif"
              >
                <td class="py-6">{{ index + 1 }}</td>
  
                <td>
                  <i class="cc mx-1" :class="item.coin"></i>{{ item.coin }}
                </td>
                <td>
                 
                 {{ item.quantity.toFixed(5) }}
              
             </td>
                <td>
                  {{ item.sellDate }}
                </td>
               
                <td :style="{ color: item.sellPrice >= item.buyPrice ? 'green' : 'red' }">
                  &#8377; {{ item.sellPrice.toFixed(5) }}
                </td>
  
                <td>
                  {{ item.buyDate }}
                </td>
                <td :style="{ color: item.buyPrice >= item.sellPrice ? 'green' : 'red' }">
                  &#8377; {{ item.buyPrice.toFixed(5) }}
                </td>
                <td :style="{ color: item.pnl >= 0 ? 'green' : 'red' }">
                  <v-icon v-if="item.pnl>0" color="green">mdi-menu-up</v-icon>
                  <v-icon v-else color="red">mdi-menu-down</v-icon>
                  {{ item.pnl.toFixed(5) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-container>
      <v-container class="mb-10 pa-5 white mt-10 pt-10" v-else>
        <h3 class="text-center mt-10">No Transactions Found</h3>
        <p class="text-center mt-4 grey--text mb-10 pb-10">
          Connect your accounts to import transactions
        </p>
      </v-container>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  export default {
    computed: {
      ...mapGetters(["TRANSACTIONS", "PNL_TOTAL", "TDS_TOTAL", "PNL"]),
      transactionStyle(type) {
        if (type.toLowerCase() == "buy" || type.toLowerCase() == "deposit") {
          return "green--text";
        } else {
          return "red--text";
        }
      },
      computedPNL (){
        return this.PNL.filter(record => record.pnl !== null);
  
      },
    },
    methods: {
      taxHarvesting() {
        this.$router.push("/tax-harvesting");
      },
      myWallet() {
        this.$router.push("/my-wallet");
      },
     
      getExchangeLogoUrl(exchangeName) {
        return require(`@/assets/icons/${exchangeName
          .toLowerCase()
          .replace(" ", "")}.png`);
      },
    },
  };
  </script>